import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import {getAuth} from 'firebase/auth'

const firebaseConfig = {
    apiKey: "AIzaSyAHjDohzLMUmaby8OMtde7q2nwcpibXEmU",
    authDomain: "usedcar-65d37.firebaseapp.com",
    projectId: "usedcar-65d37",
    storageBucket: "usedcar-65d37.appspot.com",
    messagingSenderId: "506359440465",
    appId: "1:506359440465:web:2b5acb19277a54cccd4470",
    measurementId: "G-H17LZ95N4G"
};

const authApp = firebase.initializeApp(firebaseConfig);

export default firebase;
export const db = firebase.firestore();
export const auth = getAuth(authApp);