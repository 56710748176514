import React from 'react';
import './App.css';
import { BrowserRouter,Routes,Route} from 'react-router-dom';
import useMedia from 'use-media';

//screens
import PCHome from './screens/PCHome';
import PhoneHome from './screens/PhoneHome';
import SignUp from './screens/SignUp';
import Login from './screens/Login';
import Introduction from './screens/Introduction';
import Contact from './screens/Contact';
import ErrorPage from './screens/ErrorPage';
import Welcome from './screens/Welcome';
import MemberInfomation from './screens/MemberInfomation'

function App() {
  const isWide = useMedia({minWidth:"1000px"} );
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={ isWide? <PCHome /> : <PhoneHome/>}>
          </Route>
            <Route path="/signUp/" element={<SignUp />} >
            </Route>
            <Route path = "/login" element= {<Login />}>
            </Route>
            <Route path="/introduction" element={<Introduction />} >
            </Route>
            <Route path="/contact" element={<Contact />} >
            </Route>
            <Route path="/error"  element={<ErrorPage /> } >
            </Route>
            <Route path="/welcome/:displayName" element={<Welcome /> } >
            </Route>
            <Route path="/memberInfo" element={<MemberInfomation /> } >
            </Route>
        </Routes>
      </BrowserRouter>
    );
  }

export default App;