import React from 'react';
import { Link, useParams } from 'react-router-dom';

function Parameter(){
    const Value = useParams();
    return(
        <div className="App-Welcome-Member">
            <h3 >Welcome Back To This Fantasic World {Value.displayName} !</h3>
            <div><Link to="/">ホームページに戻る。</Link></div>
        </div>
    );
} 

class Welcome extends React.Component
{
    render(){
        return<Parameter/>
    }

}



export default Welcome;