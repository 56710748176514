import React, { useContext, useState } from 'react';
import {MemberProfile} from '../TaoMotors.Domains/TaoMembers/Models/Member'
import { useLocation } from "react-router-dom"
import {serialize} from 'react-class-model';



function RootComponent() {
    const member = new MemberProfile();
    const location = useLocation();
    const a = location.state;
    const c = a.memberContext;
    member.props = c.props;
    const d = member.displayName;
    //const d = c.map((myList) =>  
    //<li>{myList}</li>);
   // const [memberInfo, setMemberInfo] = useState<{info : object}>({info : location.state})


    return (

            <div className='App-Welcome-Member'>
            {d}というDisplayNameのアカウントがログインできました。ログイン後の画面は今現在設計中です。
            </div>

    );
};


class MemberInfomation extends React.Component
{
    render() 
    {
        return (<RootComponent/>)
    }
}

export default MemberInfomation;