import React from 'react';
import { Link } from 'react-router-dom';
import useMedia from 'use-media';
import '../App.css';
import shopIcon from '../Images/Taologowithoutbackground.png';
import shopAvatar from '../Images/ManAvatar.jpg';
import {Avatar , IconButton} from "@material-ui/core";
import {WithRouter} from '../WithRouter';
//import React, { useState, useEffect } from 'react';


class PhoneHome extends React.Component {
  // constructor(){
  //   super()
  //   this.onClick
  // }

  onClick = () => {
    this.props.navigate('/login')
  }
    render() {
 /*      const Example = props => {
            const getWindowDimensions = () => {
              const { innerWidth: width, innerHeight: height } = window;
            return {
                width,
                height
              };
            } 
            const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
            useEffect(() => {
              const onResize = () => {
                setWindowDimensions(getWindowDimensions());
              }
              window.addEventListener('resize', onResize);
              return () => window.removeEventListener('resize', onResize);
            }, []);
*/     
        return (
            <div className='App-backGroud-phone'>
                <div className='App-header'>
                    <li className='lisample'><Link to="/" className='lisample'>Home</Link></li>
                    <li className='lisample'><Link to="/introduction" className='lisample'>店舗紹介</Link></li>
                    <li className='lisample'><Link to="/contact" className='lisample'>問い合わせ</Link></li>
                    <li className='lisample'>
        <IconButton className='lisample-userAvatar' color="success" onClick={this.onClick}><Avatar src ={shopAvatar} sx={{ width: 30, height: 30, color:'black'}}/>login</IconButton>
        </li>
                </div>
                <div className='App-body'>
                <div>
                      <h2>
                        <img src={shopIcon} className= 'App-logo'/>
                      </h2>
                      <h1 className= 'App-Welcome-Phone'>
                            Welcome To TaoMotors' World!
                      </h1>
                    </div>
                </div>
            </div>
        );
    }
}

export default WithRouter(PhoneHome);