import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../App.css';
import Icon from '../Images/Taologowithoutbackground.png';

import shopAvatar from '../Images/ManAvatar.jpg';
import {Avatar , IconButton} from "@material-ui/core";
import {WithRouter} from '../WithRouter';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
//import React, { useState, useEffect } from 'react';

function PCHomeFunction(){
  const [auth, setAuth] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const navigation = useNavigate();

  const handleChange = (event) => {
    setAuth(event.target.checked);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onClick = () =>{
    navigation('/Login')

  }
  return(
    <div className='App-backGroud-pc'>
    <div className='App-header'>
        <li className='lisample'><Link to="/" className='lisample'>Home</Link></li>
        <li className='lisample'><Link to="/introduction" className='lisample'>店舗紹介</Link></li>
        <li className='lisample'><Link to="/contact" className='lisample'>問い合わせ</Link></li>
        <li className='lisample'>
        <IconButton className='lisample-userAvatar' color="success" onClick={onClick}><Avatar src ={shopAvatar} sx={{ width: 30, height: 30 }}/>login</IconButton>
        </li>
    </div>
    <div className='App-body'>
        <div>
          <div>
            <img src={Icon} className= 'App-logo'/>
          </div>
          <h1 className= 'App-Welcome-pc'>
                Welcome To TaoMotors' World!
          </h1>
        </div>
    </div>
              <Box sx={{ flexGrow: 1 }}>
                <FormGroup>
                <FormControlLabel
                control={
                <Switch
                  checked={auth}
                  onChange={handleChange}
                  aria-label="login switch"
                />
                }
                label={auth ? 'Logout' : 'Login'}
                />
                </FormGroup>
                <AppBar position="static">
                <Toolbar>
                <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 2 }}
                >
                <MenuIcon />
                </IconButton>
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                Photos
                </Typography>
                {auth && (
                <div>
                  <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleMenu}
                    color="inherit"
                  >
                    <AccountCircle />
                  </IconButton>
                  <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                  >
                    <MenuItem onClick={handleClose}>Profile</MenuItem>
                    <MenuItem onClick={handleClose}>My account</MenuItem>
                  </Menu>
                </div>
                )}
                    </Toolbar>
                    </AppBar>
                    </Box>
                    </div>

  );

}

class PCHome extends React.Component {

    render() {
 /*      const Example = props => {
            const getWindowDimensions = () => {
              const { innerWidth: width, innerHeight: height } = window;
            return {
                width,
                height
              };
            } 
            const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
            useEffect(() => {
              const onResize = () => {
                setWindowDimensions(getWindowDimensions());
              }
              window.addEventListener('resize', onResize);
              return () => window.removeEventListener('resize', onResize);
            }, []);
*/     
        return (<PCHomeFunction />);
    }
}

export default WithRouter(PCHome) ;