import React from 'react';
import { Link } from 'react-router-dom';

class ErrorPage extends React.Component{
    render(){
        return(
            <div className="container">
                <h3 className="text-center my-5">処理に失敗しました。</h3>
                <div className="text-center"><Link to="/">ホームページに戻る。</Link></div>
            </div>
        );
    }
}

export default ErrorPage;