import React from 'react';
import { Form, FormGroup, Label, Input, Button, FormFeedback} from 'reactstrap';
import { Link} from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import firebase, { db } from '../Firebase';
import {createUserWithEmailAndPassword, onAuthStateChanged, signInWithEmailAndPassword} from 'firebase/auth';
import {auth} from '../Firebase'; 
import LoadingButton from '@mui/lab/LoadingButton';
import SendIcon from '@mui/icons-material/Send';
import {WithRouter} from '../WithRouter';
import Box from '@mui/material/Box';

//import { sendSignInLinkToEmail } from 'firebase/auth';

//import {
//    MuiPickersUtilsProvider,
//    KeyboardDatePicker
//  } from "@material-ui/pickers";
  
//  import DateFnsUtils from "@date-io/date-fns";
//import { date, dateTime } from 'date-fns/locale/af';


class SignUp extends React.Component 
{
           constructor(props){
            super(props);
            this.state = {value: false}
           }
           // 登録ボタンが押されたら
           handleOnSubmit = async (values) => {
            this.setState({value: true});
            try {
                await createUserWithEmailAndPassword (
                  auth,
                  values.email,
                  values.password
                );

                await signInWithEmailAndPassword (
                    auth,
                    values.email,
                    values.password
                  );

                const docId = auth.currentUser.uid;

                db.collection("TaoMotorsMember").doc(docId).set({
                    docId: docId,
                    email: values.email,
                    displayName: values.displayName,
                    familyName: values.familyName,
                    givenName: values.givenName,
                    dateOfBirth: values.dateOfBirth,
                    contactPhoneNumber: values.phoneNumber,
                    postCode: values.postCode,
                    homeAddress: values.address,
                    createdAt: firebase.firestore.FieldValue.serverTimestamp(),
                });
              } 
              catch(error) {
                alert(error.message);
                console.error(error);

                this.props.navigate('/error')

              }

              this.setState({value: false});

              alert("ユーザ登録完了！");

              this.props.navigate('/')

            }
            
        
    render() 
    {
        return (
            <div>
                <div className='App-header'>
                    <li className='lisample'><Link to="/" className='lisample'>Home</Link></li>
                    <li className='lisample'><Link to="/introduction" className='lisample'>店舗紹介</Link></li>
                </div>

            <Box
                sx={{
                    marginTop: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}>

                <h1 className='loginTitle'>会員登録</h1>
                <h6>必須項目を入力した上で会員登録ボタンを押してください。</h6>



                <Formik
                    initialValues={{ email: '' , password: '' , displayName: '', familyName:'', givenName: '', dateOfBirth: null , phoneNumber: '', postCode:'', address:''}}
                    onSubmit={values => this.handleOnSubmit(values)}
                    validationSchema={Yup.object().shape({
                        email: Yup.string().email('emailの形式ではありません。').required('Emailは必須です。').max(50),
                        password: Yup.string().required('PassWordは必須です。').min(8).matches(/^[a-zA-Z0-9]+$/, { message: '文字列が有効ではありません' }),
                        displayName: Yup.string().required('表示名の入力は必須です。').max(30),
                        familyName: Yup.string().required('姓の入力は必須です。').max(20),
                        givenName: Yup.string().required('名の入力は必須です。').max(20),
                        dateOfBirth: Yup.date('yyyy/mm/dd'),
                        phoneNumber: Yup.string().required('連絡電話番号の入力は必須です。。').max(15),
                        postCode: Yup.string().required('郵便番号の入力は必須です。').max(7),
                        address: Yup.string().required('住所の入力は必須です。').max(30),
                    })}
                >
                    {  
                        ({ handleSubmit, handleChange, handleBlur, values, errors, touched}) => (
                            <Form onSubmit={handleSubmit}>

                                <FormGroup >
                                    <Label for="email" className='m-form-title'>メールアドレス　Email</Label>
                                    <Input className='m-form-text'
                                        type="email"
                                        email="email"
                                        id="email"
                                        value={values.email}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        invalid={Boolean(touched.email && errors.email)}
                                        placeholder="TaoMotors@example.com"
                                    />
                                    <FormFeedback>
                                        {errors.email}
                                    </FormFeedback>
                                </FormGroup>

                                <FormGroup>
                                    <Label for="password" className='m-form-title'>パスワード　password</Label>
                                    <Input className='m-form-text'
                                        type="password"
                                        name="password"
                                        id="password"
                                        value={values.password}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        invalid={Boolean(touched.password && errors.password)}
                                        secureTextEntry={true}
                                        placeholder="英数字、大文字、小文字の組み合わせで8文字以上に設定してください。"
                                    />
                                    <FormFeedback>
                                        {errors.password}
                                    </FormFeedback>
                                </FormGroup>

                                <FormGroup>
                                    <Label for="displayName" className='m-form-title'>表示名 displayName</Label>
                                    <Input className='m-form-text'
                                        type="text"
                                        id="displayName"
                                        value={values.displayName}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        invalid={Boolean(touched.displayName && errors.displayName)}
                                        placeholder="30文字以内"
                                    />
                                    <FormFeedback>
                                        {errors.familyName}
                                    </FormFeedback>
                                </FormGroup>

                                <FormGroup>
                                    <Label for="familyName" className='m-form-title'>姓 FamilyName</Label>
                                    <Input className='m-form-text'
                                        type="text"
                                        id="familyName"
                                        value={values.familyName}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        invalid={Boolean(touched.familyName && errors.familyName)}
                                        placeholder="Tao"
                                    />
                                    <FormFeedback>
                                        {errors.familyName}
                                    </FormFeedback>
                                </FormGroup>

                                <FormGroup>
                                    <Label for="givenName" className='m-form-title'>名 GivenName</Label>
                                    <Input className='m-form-text'
                                        type="text"
                                        id="givenName"
                                        value={values.givenName}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        invalid={Boolean(touched.givenName && errors.givenName)}
                                        placeholder="Motors"
                                    />
                                    <FormFeedback>
                                        {errors.givenName}
                                    </FormFeedback>
                                </FormGroup>

                                <FormGroup>
                                    <Label for="dateOfBirth" className='m-form-title'>誕生日 DateOfBirth</Label>
                                    <Input className='m-form-text'
                                        type="date"
                                        format="年/月/日"
                                        id="dateOfBirth"
                                        value={values.dateOfBirth}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        invalid={Boolean(touched.dateOfBirth && errors.dateOfBirth)}
                                    />
                                    <FormFeedback>
                                        {errors.DateOfBirth}
                                    </FormFeedback>
                                </FormGroup >

                                <FormGroup>
                                    <Label for="phoneNumber" className='m-form-title'>連絡電話番号 Contact PhoneNumber</Label>
                                    <Input className='m-form-text'
                                        type="text"
                                        id="phoneNumber"
                                        value={values.phoneNumber}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        invalid={Boolean(touched.phoneNumber && errors.phoneNumber)}
                                        placeholder="09011112222"
                                    />
                                    <FormFeedback>
                                        {errors.phonenNumber}
                                    </FormFeedback>
                                </FormGroup>

                                <FormGroup>
                                    <Label for="postCode" className='m-form-title'>郵便番号 Post code</Label>
                                    <Input className='m-form-text'
                                        type="text"
                                        id="postCode"
                                        value={values.postCode}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        invalid={Boolean(touched.postCode && errors.postCode)}
                                        placeholder="7001234"
                                    />
                                    <FormFeedback>
                                        {errors.postCode}
                                    </FormFeedback>
                                </FormGroup>

                                <FormGroup>
                                    <Label for="address"  className='m-form-title'>住所 Home Address</Label>
                                    <Input 
                                        className='m-form-text'
                                        type="text"
                                        id="address"
                                        value={values.address}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        invalid={Boolean(touched.address && errors.address)}
                                        placeholder="広島県広島市中区大手町２−２−５"
                                    />
                                    <FormFeedback>
                                        {errors.address}
                                    </FormFeedback>
                                </FormGroup>

                                <LoadingButton
                                            size="medium"
                                            type='submit'
                                            endIcon={<SendIcon />}
                                            loading = {this.state.value}
                                            loadingPosition="end"
                                            variant="contained"
                                            className='m-form-submit-button'
                                            >
                                            登録
                                </LoadingButton>
                            </Form>
                        )
                    }
                </Formik>
            </Box>
            </div>
        )
    }
}

export default WithRouter(SignUp);