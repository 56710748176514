import React, { createContext, useEffect, useState } from 'react';
import { Form, FormGroup, Label, Input, FormFeedback} from 'reactstrap';
import { Link} from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {signInWithEmailAndPassword} from 'firebase/auth';
import {auth} from '../Firebase'; 
import LoadingButton from '@mui/lab/LoadingButton';
import SendIcon from '@mui/icons-material/Send';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import {GetTaoMembersData} from '../GetTaoMembersData.ts';
import {IconButton} from "@material-ui/core";
import CloseIcon from '@mui/icons-material/Close';
import Collapse from '@mui/material/Collapse';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import {WithRouter} from '../WithRouter';
import { useNavigate } from "react-router-dom"
import {MemberProfile, MemerProfileProvider} from '../TaoMotors.Domains/TaoMembers/Models/Member'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Avatar from '@mui/material/Avatar';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { fontSize } from '@material-ui/system';

//import { sendSignInLinkToEmail } from 'firebase/auth';

//import {
//    MuiPickersUtilsProvider,
//    KeyboardDatePicker
//  } from "@material-ui/pickers";
  
//  import DateFnsUtils from "@date-io/date-fns";
//import { date, dateTime } from 'date-fns/locale/af';

function LoginFunction(){
    const [loading, setLoading] = useState(false);
    const [isError, setError] = useState(false);
    const [isSucceed, setSucceed] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [displayName, setDisplayName] = useState('');
    const [goTime, setGoTime] = useState(5);
    const [memberContext, setMemberContext] = useState(() =>new MemberProfile() )
    const [secureTextEntry, setSecureTextEntry] = useState(false);
    const [authState, setAuthState] = useState(false);
    const navigation = useNavigate();

    const secureTextEntryBottonOnClick = ()=>{
        setSecureTextEntry(!secureTextEntry)
    }


    const handleOnSubmit = async (values) => {

        setLoading(true);
  
        try {
            await signInWithEmailAndPassword (
              auth,
              values.email,
              values.password
            )

            if(auth.currentUser == null)
            {
                throw new Error('認証に失敗しました。メールアカウントとパスワードを確認してもう一度ログインしてみてください。パスワードを忘れた場合、パスワード忘れのボタンを押してパスワードを再設定してください。')
            }

            setAuthState(true);

            const memberAuthId = auth.currentUser.uid;
            
            const memberModel = await GetTaoMembersData('TaoMotorsMember', memberAuthId);

            setMemberContext(memberModel);
            setDisplayName(memberModel.displayName);
            setSucceed(true);
            setSuccessMessage('Welcome Back To This Fantasic World  ' + memberModel.displayName);
            
           // this.props.navigate('/welcome/' + memberModel.displayName);
          } 
          catch(error) {

            setError(true);
            const isRightPassWord = error.message.includes('Firebase: Error (auth/wrong-password).');
            
            if(isRightPassWord)
            {

                setErrorMessage('メールアドレスかパスワードが間違えています。');

            }else{
                setErrorMessage(error.message);
            }
        
            console.error(error);

            setLoading(false);
          }
        }

        if(isSucceed)
        {
                const interval = setTimeout(() => {
                    setGoTime(c => c - 1);
                }, 1000);
                if(goTime < 0)
                {
                    clearTimeout(interval);
                    //navigation('/welcome/' + displayName);
                    navigation('/memberInfo', {state: {memberContext, authState}, replace : true});
                }
        }


        return(           
         <div>
            <div className='App-header'>
                <li className='lisample'><Link to="/" className='lisample'>Home</Link></li>
                <li className='lisample'><Link to="/introduction" className='lisample'>店舗紹介</Link></li>
            </div>
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    }}>
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
            </Avatar>
            <h1 className='loginTitle'>会員ログイン</h1>

            </Box>
            <Box
                    sx={{
                        marginTop: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}>
            <Formik
                initialValues={{ email: '' , password: ''}}
                onSubmit={values => handleOnSubmit(values)}
                validationSchema={Yup.object().shape({
                    email: Yup.string().email('emailの形式ではありません。').required('Emailは必須です。').max(50),
                    password: Yup.string().required('PassWordは必須です。').min(8).matches(/^[a-zA-Z0-9]+$/, { message: '文字列が有効ではありません' })
                })}
            >
                {  
                    ({ handleSubmit, handleChange, handleBlur, values, errors, touched}) => (
                        <Form onSubmit={handleSubmit}>

                                    <FormGroup >
                                        <Box sx={{position:'relative', left:20}}>
                                        <Input className='m-form-text-login'
                                            type="email"
                                            email="email"
                                            id="email"
                                            value={values.email}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            invalid={Boolean(touched.email && errors.email)}
                                            placeholder="TaoMotors@example.com"
                                        />
                                        <FormFeedback>
                                            {errors.email}
                                        </FormFeedback>
                                        </Box>
                                    </FormGroup>
                                    <FormGroup>
                                        <Box 
                                            sx={{
                                                    marginTop: 1,
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    alignItems: 'center',
                                                }}>
                                            <Box sx={{position:'relative', left:20}}>
                                            <Input className='m-form-text-login'
                                                type={secureTextEntry? 'text' : 'password'}
                                                name="password"
                                                id="password"
                                                value={values.password}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                invalid={Boolean(touched.password && errors.password)}
                                                secureTextEntry= {true}
                                                placeholder="大文字、小文字の違いに注意してください！"
                                            />
                                            </Box>
                                            <Box>
                                            <IconButton onClick={secureTextEntryBottonOnClick} sx={{position:'relative', left:30}}>
                                                    {
                                                    secureTextEntry? <VisibilityIcon /> : <VisibilityOffIcon />
                                                    }
                                            </IconButton>
                                            </Box>
                                        </Box>
                                        <Box >
                                            <FormFeedback>
                                                {errors.password}
                                            </FormFeedback>
                                         </Box>


                                    </FormGroup>    

                            
                            <Box sx={ {display: 'flex',
                                       flexDirection: 'column',
                                       alignItems: 'center',
                                      }}>
                                <LoadingButton
                                            sx={{marginTop:2}}
                                            size="medium"
                                            type='submit'
                                            endIcon={<SendIcon />}
                                            loading = {loading}
                                            loadingPosition="end"
                                            variant="contained"
                                            className='m-form-submit-button-login'>
                                            ログイン
                                </LoadingButton>

                                <LoadingButton
                                            sx={{marginTop:2}}
                                            size="medium"
                                            type='submit'
                                            endIcon={<AccountBoxIcon />}
                                            disabled={loading}
                                            variant="contained"
                                            className='m-form-submit-button-login'>
                                            <Link to ="/signUp" className="botton-text">会員登録</Link>                        
                                </LoadingButton>
                            </Box>  
                    </Form>
                      

                    )
                }
            </Formik>

            <Box sx={{  width: '50%', 
                        display: 'flex',
                        flexDirection: 'column',
                         alignItems: 'center' }}>
                                <Collapse in={isError} >
                                    <Alert
                                    action={
                                        <IconButton
                                                    aria-label="close"
                                                    color="inherit"
                                                    size="small"
                                                    onClick={() => {
                                                    setError(false)}}>
                                            <CloseIcon fontSize="inherit" />
                                        </IconButton>}
                                    severity="error"
                                    sx={{ mb: 3 }}>
                                        <AlertTitle>Error</AlertTitle>
                                    {errorMessage}
                                    </Alert>
                                </Collapse>
            </Box> 
            <Box sx={{  width: '50%', 
                        display: 'flex',
                        flexDirection: 'column',
                         alignItems: 'center'}}>
                                <Collapse in={isSucceed}>
                                    <Alert
                                    action={
                                        <IconButton
                                                    aria-label="close"
                                                    color="inherit"
                                                    size="small"
                                                    onw
                                                    onClick={() => {
                                                    setSucceed(false);
                                                    navigation('/welcome/' + displayName);
                                                    }}>
                                            <CloseIcon fontSize="inherit" />
                                        </IconButton>}
                                    severity="success"
                                    sx={{ mb: 3 }}>
                                        <AlertTitle>Success</AlertTitle>
                                    {successMessage}
                                    <div>
                                    {goTime+'秒後にページが遷移します。'}
                                    </div>
                                     
                                    </Alert>
                                </Collapse>
            </Box> 
        </Box>
    </div>)

}

class Login extends React.Component 
{       
    render() 
    {
        return (<LoginFunction/>)
    }
}

export default WithRouter(Login);