import {Model, watch , prop, key, deserializeInto, defineModel} from 'react-class-model';

export class MemberProfile extends Model{
    @watch @prop()
    public contactPhoneNumber: string;

    @watch @prop()
    public createAt: string;

    @watch @prop()
    public dateOfBirth: string;

    @watch @prop() @key
    public docId: string;

    @watch @prop()
    public email: string;

    @watch @prop()
    public displayName: string;

    @watch @prop()
    public familyName: string;

    @watch @prop()
    public givenName: string;

    @watch @prop()
    public homeAddress: string;

    @watch @prop() 
    public postCode: string;

    constructor() {
        super();
        this.contactPhoneNumber = 'Unknown';
        this.createAt = 'Unknown';
        this.dateOfBirth = 'Unknown';
        this.docId = 'Unknown';
        this.email = 'Unknown';
        this.displayName = 'Unknown';
        this.familyName = 'Unknown';
        this.givenName = 'Unknown';
        this.homeAddress = 'Unknown';
        this.postCode = 'Unknown';
    }


    public updateDisplyName(newDisplyName : string){
        if(!newDisplyName){
            throw new Error('DisplayName can not be empty');
        }

        this.displayName = newDisplyName;
    }

    public updatePhoneNumber(newphoneNumber : string){
        if(!newphoneNumber){
            throw new Error('ContactPhoneNumber can not be empty');
            
        }

        this.contactPhoneNumber = newphoneNumber;
    }

    public updatepostCode(newPostCode : string){
        if(!newPostCode){
            throw new Error('PostCode can not be empty');
            
        }

        this.postCode = newPostCode;
    }

    public updateHomeAddress(newHomeAddress : string){
        if(!newHomeAddress){
            throw new Error('HomeAddress can not be empty');
            
        }

        this.homeAddress = newHomeAddress;
    }


    public updateFromJson(json: string) {
        
        deserializeInto(this, json);
    }

}

export const [MemerProfileProvider, useMemberProfile] = defineModel<MemberProfile>();