


import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {Avatar , IconButton} from "@material-ui/core";
import { useState } from 'react';
import shopAvatar from '../Images/ManAvatar.jpg';
import IntroductionIcon from '../Images/projects.jpg';

function IntroductionFunction(){
    const navigation = useNavigate();

    const onClick = () =>{
        navigation('/Login')
    
      }
      return(

                    
        <div>
        <div className='App-header'>
            <li className='lisample'><Link to="/" className='lisample'>Home</Link></li>
            <li className='lisample'><Link to="/contact" className='lisample'>問い合わせ</Link></li>
            <li className='lisample'>
<IconButton className='lisample-userAvatar' color="success" onClick={onClick}><Avatar src ={shopAvatar} sx={{ width: 30, height: 30 }}/>login</IconButton>
</li>
        </div>
        <h3>
        <img src={IntroductionIcon} className= 'App-Introduction'/>
        </h3>
        <div><Link to="/">ホームページに戻る</Link></div>
    </div>



      )

}
class Introduction extends React.Component {
    render() {
        return ( 
            <IntroductionFunction />
        );
    }
}

export default Introduction;