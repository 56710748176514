import { doc, getDoc ,DocumentData, getDocs, collection} from 'firebase/firestore';
import { db } from './Firebase';
import { useState } from 'react';
import {MemberProfile, MemerProfileProvider} from './TaoMotors.Domains/TaoMembers/Models/Member'


async function GetMemberModel (collectionName : string , docId : string){

  var data = null;

  const model = new MemberProfile();

  const docRef = doc(db, collectionName, docId);

  const docSnap = await getDoc(docRef);

  if(!docSnap.exists)
  {
    throw new Error('There is no data found');
  }
  else
  {
     data = docSnap.data();

     model.contactPhoneNumber = data.contactPhoneNumber;
     model.dateOfBirth = data.dateOfBirth;
     model.displayName = data.displayName;
     model.docId = data.docId;
     model.email = data.email;
     model.familyName = data.familyName;
     model.givenName = data.givenName;
     model.homeAddress = data.homeAddress;
  }
  return model
}






const AllMembersData   = async (collectionName : string ) =>{
  var data = null;

  const conRef = collection(db, collectionName);

  const querrySnap = await getDocs(conRef);

  const [lists, setLists] = useState([]);
  const members = [];
  querrySnap.forEach((doc)=> {
    members.push(doc.data());
  });

  setLists(members);

  return lists;
}

export const GetTaoMembersData = async (collectionName : string , docId : string) =>{

  return await GetMemberModel(collectionName, docId);

}

export const GetAllTaoMembersData = AllMembersData;

