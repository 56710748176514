import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {Avatar , IconButton} from "@material-ui/core";
import { useState } from 'react';
import shopAvatar from '../Images/ManAvatar.jpg';

function ContactFunction(){
    const navigation = useNavigate();

    const onClick = () =>{
        navigation('/Login')
    
      }
      return(

                    
        <div className='App-backGroud-gif'>
        <div className='App-header'>
            <li className='lisample'><Link to="/" className='lisample'>Home</Link></li>
            <li className='lisample'><Link to="/introduction" className='lisample'>店舗紹介</Link></li>
            <li className='lisample'>
<IconButton className='lisample-userAvatar' color="success" onClick={onClick}><Avatar src ={shopAvatar} sx={{ width: 30, height: 30 }}/>login</IconButton>
</li>
        </div>
        <div className='lisample'>Tel: 090-2866-5166</div>
        <h5><Link to="/" className='lisample-column'>ホームページに戻る</Link></h5>
    </div>



      )

}
class Contact extends React.Component {
    render() {
        return ( 
            <ContactFunction />
        );
    }
}

export default Contact;